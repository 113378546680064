import axios from "axios"
import { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as Cookies from "js-cookie"

export const useDilemmaApi = (slug) => {
  const [data, setData] = useState(null)
  const [hasData, sethasData] = useState(false)
  const [error, setError] = useState(null)

  const updateFunction = (left_or_right) => {
    axios.post(`/api/dilemmaUpdate?slug=${slug}&pair=${left_or_right}`)
      .then(({ data }) => {
        setData(data)
        sethasData(true)
      })
      .catch((e) => {
        setError(`Something went wrong: ${e}`)
        sethasData(true)
      })
  }

  return [hasData, data, error, updateFunction]
}

function sampleArray(array) {
  return array[Math.floor(Math.random() * array.length)]
}

export const useRandomNextCard = (currentCard) => {
  var seen = (Cookies.get("dilemmas") || "")
  var card = null

  // If currentcard is given and not already seen, add it to seen
  // if no currentCard is give, clear seen so a new game can be started
  if (currentCard !== null && !seen.includes(currentCard)) {
    seen += currentCard
  } else if (currentCard === null) {
    seen = ""
  }
  Cookies.set("dilemmas", seen);

  const { allSitePage: { edges: cards } } = useStaticQuery(
    graphql`
      query cardsQuery {
        allSitePage(filter: {path: {glob: "/cards/*"}}) {
          edges {
            node {
              id
              path
              context {
                slug
              }
            }
          }
        }
      }
    `
  )

  const availableCards = cards.filter(card => !seen.includes(card.node.context.slug))
  if (availableCards.length > 0) {
    card = sampleArray(availableCards).node
  }

  const [nextCard, setNextCard] = useState(card)

  useEffect(() => {
    setNextCard(card)
  }, [cards, seen])

  return [nextCard]
}
