import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "gatsby"
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGameConsoleHandheld } from '@fortawesome/pro-solid-svg-icons';

const Footer = () => (
  <footer className="bg-gray-900 border-t border-gray-800 px-8 py-4 text-gray-300 text-center lg:hidden">
    <a
      href="https://twitter.com/intent/tweet/?text=Play Developer Dilemmas by @AppSignal 🤯💥&url=https://developerdilemmas.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="block my-4 no-underline">
      Tweet about this
      <FontAwesomeIcon className="ml-2" icon={faTwitter}/>
    </a>
    <Link to="/get" className="block my-4 no-underline">
      Get physical copy
      <FontAwesomeIcon className="ml-2" icon={faGameConsoleHandheld} />
    </Link>
  </footer>
)

Footer.propTypes = {
}

Footer.defaultProps = {
}

export default Footer
