import "@appsignal/design-system"
import "@fortawesome/fontawesome-svg-core/styles.css";
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false; /* eslint-disable import/first */

import PropTypes from "prop-types"
import React from "react"

import Footer from "./footer"
import Header from "./header"
import SEO from "./seo"

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <div className="bg-gray-900 text-white min-h-screen font-museo ">
        <Header />
        <div className="pt-12">
          {children}
        </div>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
