import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "gatsby"
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGift, faCheckCircle, faHeart } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/appsignal-logo.svg"

const Header = () => (
  <header className="absolute left-0 right-0 top-0 bg-gray-900 border-b border-gray-800 ">
    <div className="container mx-auto flex items-center justify-center lg:justify-between relative">
      <Link to="/get" className="px-8 no-underline hidden lg:block">
        <FontAwesomeIcon className="mr-2" icon={faGift} />
        Get a free card game
      </Link>
      <div className="group">
        <Link to="/" className="px-8 py-4 no-underline block">
          <img src={Logo} className="h-5" />
        </Link>
        <div className="c-box p-6 invisible group-hover:visible absolute left-half top-full -mt-2 -translate-x-half w-96 text-gray-800 text-center z-50">
          <h2 className="c-heading--2xl">Application Monitoring</h2>
          <p className="c-p">This game was made with <FontAwesomeIcon icon={faHeart} className="text-pink" /> by the friendly devs at AppSignal. We build an easy to use, all-in-one application monitoring tool.</p>
          <div className="grid grid-cols-2 col-gap-4 mb-4 row-gap-2 text-left text-sm">
            <span>
              <FontAwesomeIcon icon={faCheckCircle} className="text-green mr-1" /> Exception tracking
            </span>
            <span>
              <FontAwesomeIcon icon={faCheckCircle} className="text-green mr-1" /> Performance
            </span>
            <span>
              <FontAwesomeIcon icon={faCheckCircle} className="text-green mr-1" /> Smart alerting
            </span>

            <span>
              <FontAwesomeIcon icon={faCheckCircle} className="text-green mr-1" /> Server monitoring
            </span>
            <span>
              <FontAwesomeIcon icon={faCheckCircle} className="text-green mr-1" /> Custom metrics
            </span>

            <span>
              <FontAwesomeIcon icon={faCheckCircle} className="text-green mr-1" /> Dashboards
            </span>

          </div>
          <a
            href="https://appsignal.com/?utm_source=developerdilemmas&utm_medium=website&utm_campaign=developerdilemmas"
            target="_blank"
            rel="noopener noreferrer"
            className="c-button c-button--gray c-button--sm"
            >
              Check out AppSignal
            </a>
        </div>
      </div>
      <a
        href="https://twitter.com/intent/tweet/?text=I just played Developer Dilemmas by @AppSignal 🤯💥&url=https://developerdilemmas.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="px-8 no-underline hidden lg:block">
        Tweet about this
        <FontAwesomeIcon className="ml-2" icon={faTwitter}/>
      </a>
    </div>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
